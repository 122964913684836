<template>
  <div class="row" id="contact">
    <div class="col-md-12 contact-form" id="form-contact">
      <Form
        ref="form"
        @submit="sendMessage"
        data-request="contactform::onSend"
        data-request-validate
        data-request-flash
        v-slot="{ errors }"
      >
        <div class="row form-group">
          <div class="col-lg-6 col-md-12 mb-3 mb-md-2">
            <Field
              type="text"
              name="contact.name"
              v-model="name"
              class="form-control"
              placeholder="Nume si prenume"
              :rules="validateName"
              :class="{ error: errors['contact.name'] }"
            />
            <ErrorMessage class="form-error" name="contact.name" />
          </div>
          <div class="col-lg-6 col-md-12">
            <Field
              type="text"
              name="contact.address"
              value=""
              class="form-control"
              placeholder="Adresa"
            />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-lg-6 col-md-12 mb-3 mb-md-2">
            <Field
              type="text"
              name="contact.email"
              value=""
              class="form-control"
              placeholder="Email"
            />
          </div>
          <div class="col-lg-6 col-md-12">
            <Field
              type="text"
              name="contact.phone"
              value=""
              class="form-control"
              placeholder="Telefon"
              :rules="validatePhone"
              :class="{ error: errors['contact.phone'] }"
            />
            <ErrorMessage class="form-error" name="contact.phone" />
          </div>
        </div>
        <div class="row form-group">
          <div class="col-md-12">
            <Field
              as="textarea"
              name="contact.message"
              placeholder="Mesaj"
              rows="3"
              cols="40"
              value=""
              class="form-control"
              :rules="validateMessage"
              :class="{ error: errors['contact.message'] }"
            />
            <ErrorMessage class="form-error" name="contact.message" />
          </div>
        </div>

        <div class="row form-group pt20">
          <div class="col-lg-5 mb-3 mb-md-2 recaptcha">
            <Field
              v-if="recaptchaSiteKey?.recaptchaSiteKey"
              name="recaptcha"
              :rules="validateRecap"
            >
              <vue-recaptcha
                :sitekey="recaptchaSiteKey.recaptchaSiteKey"
                language="ro"
                @verify="handleSuccess"
                @expired="handleExpired"
              ></vue-recaptcha>
            </Field>
            <ErrorMessage class="form-error" name="recaptcha" />
          </div>
          <div class="col-lg-7 terms">
            <Field
              type="checkbox"
              name="policy"
              value="policy"
              id="checkout_agree2"
              :rules="validateCheckbox"
            />
            <label class="checkout_value inline" for="checkout_agree2"
              >Sunt de acord cu
              <a href="/politica-de-confidentialitate-a-datelor.html" target="_blank"
                >politica de confidentialitate a datelor
                <span class="steluta"> *</span></a
              ></label
            >
            <br />
            <ErrorMessage class="form-error" name="policy" />
          </div>
        </div>
        <div class="row form-group pt20">
          <div class="col-12">
            <input
              type="submit"
              name="trimite"
              value="Trimite mesaj"
              class="btn btn-red form-btn contact-form-btn"
            />
          </div>
        </div>
      </Form>

      <!-- <button @click="recaptcha">Execute recaptcha</button> -->
      <ContactResponseModal />
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ContactResponseModal from "@/components/contact/ContactResponseModal.vue";
// import axios from "axios";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "ContactForm",

  data() {
    return {
      name: "",
      hasError: false,
      submitted: false,
      recaptcha: false,
      recaptchaToken: "",
    };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
    ContactResponseModal,
    VueRecaptcha,
  },
  computed: {
    recaptchaSiteKey() {
      return this.$store.getters["shop/currency"];
    },
  },
  methods: {
    async sendMessage(values, { resetForm }) {
      // Wait until recaptcha has been loaded.
      // await this.$recaptchaLoaded();
      // Execute reCAPTCHA with action "login".
      // const token = await this.$recaptcha("login");

      values.contact.token = this.recaptchaToken;
      this.$store.dispatch("forms/responseContact", true);
      this.$store.dispatch("forms/sendContactForm", values.contact).then((response) => {
        console.log(response.status);
        if (response?.status === "success") {
          resetForm();
        }
      });
    },
    handleSuccess(value) {
      this.recaptcha = true;
      console.log(this.$refs.form);
      this.recaptchaToken = value;
      this.$refs.form.validateField("recaptcha");
      // return value;
    },
    handleExpired() {
      this.recaptcha = false;
      this.recaptchaToken = "";
      this.$refs.form.validateField("recaptcha");
    },
    validateName(value) {
      if (!value) {
        return "NUMELE ESTE OBLIGATORIU!";
      }
      return true;
    },
    validatePhone(value) {
      if (!value) {
        return "NUMAR TELEFON (VALID) OBLIGATORIU!";
      }
      return true;
    },
    validateCheckbox(value) {
      if (!value) {
        return "ESTE NECESAR SA BIFATI CASUTA PENTRU A CONTINUA!";
      }
      return true;
    },
    validateMessage(value) {
      if (!value) {
        return "MESAJUL ESTE OBLIGATORIU!";
      }
      return true;
    },
    validateRecap() {
      if (!this.recaptcha) {
        return "RECAPTCHA NU ESTE VALIDAT";
      }
      return true;
    },
  },
  // computed: {
  //   displayError() {
  //     return this.hasError = true;
  //     // console.log('dada');
  //     // if (name === '') {
  //     //   return this.hasError = true;
  //     // }
  //   },
  // },
};
</script>
